import * as React from "react";
import { Base } from "../../../styles";
const Chevron = () => /* @__PURE__ */ React.createElement(Base, {
  as: "svg",
  width: "100%",
  height: "471",
  viewBox: "0 0 720 471",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  maxHeight: ["300px", "unset"]
}, /* @__PURE__ */ React.createElement("path", {
  d: "M1192 1295.82L359.949 461.312L-472.102 1295.82",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 1265.89L359.949 431.376L-472.102 1265.89",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 1235.98L359.949 401.469L-472.102 1235.98",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 1206.04L359.949 371.533L-472.102 1206.04",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 1176.14L359.949 341.627L-472.102 1176.14",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 1146.2L359.949 311.691L-472.102 1146.2",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 1116.3L359.949 281.785L-472.102 1116.3",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 1086.39L359.949 251.849L-472.102 1086.39",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 1056.45L359.949 221.942L-472.102 1056.45",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 1026.55L359.949 192.036L-472.102 1026.55",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 996.612L359.949 162.1L-472.102 996.612",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 966.705L359.949 132.194L-472.102 966.705",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 936.769L359.949 102.258L-472.102 936.769",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 906.863L359.949 72.3514L-472.102 906.863",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 876.927L359.949 42.4156L-472.102 876.927",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 847.02L359.949 12.5093L-472.102 847.02",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 817.085L359.949 -17.4266L-472.102 817.085",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 787.178L359.949 -47.333L-472.102 787.178",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 757.242L359.949 -77.2689L-472.102 757.242",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 727.336L359.949 -107.175L-472.102 727.336",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 697.4L359.949 -137.111L-472.102 697.4",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 667.494L359.949 -167.018L-472.102 667.494",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 637.558L359.949 -196.953L-472.102 637.558",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 607.651L359.949 -226.86L-472.102 607.651",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 577.745L359.949 -256.796L-472.102 577.745",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 547.809L359.949 -286.702L-472.102 547.809",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 517.903L359.949 -316.609L-472.102 517.903",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 487.967L359.949 -346.544L-472.102 487.967",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 458.06L359.949 -376.451L-472.102 458.06",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 428.124L359.949 -406.387L-472.102 428.124",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 398.218L359.949 -436.293L-472.102 398.218",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 368.282L359.949 -466.229L-472.102 368.282",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 338.376L359.949 -496.135L-472.102 338.376",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 308.44L359.949 -526.071L-472.102 308.44",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 278.533L359.949 -555.978L-472.102 278.533",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 248.598L359.949 -585.914L-472.102 248.598",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 218.691L359.949 -615.82L-472.102 218.691",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 188.755L359.949 -645.756L-472.102 188.755",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 158.849L359.949 -675.662L-472.102 158.849",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 128.913L359.949 -705.598L-472.102 128.913",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 99.0066L359.949 -735.505L-472.102 99.0066",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 69.1001L359.949 -765.44L-472.102 69.1001",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 39.1643L359.949 -795.347L-472.102 39.1643",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}), /* @__PURE__ */ React.createElement("path", {
  d: "M1192 9.25774L359.949 -825.253L-472.102 9.25774",
  stroke: "white",
  strokeWidth: "2.5",
  strokeMiterlimit: "10"
}));
export default Chevron;
