import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { Base, Text, StyledGatsbyImage } from "../../styles";
import ContentGutter from "../ContentGutter";
import theme from "../../styles/theme";
import styled from "styled-components";
const CardWrapper = styled(Base)({
  gridColumnGap: "40px"
});
const CardGrid = forwardRef(({ cards }, ref) => {
  let cardRefArr = [];
  cards.forEach((card, index) => {
    cardRefArr[index] = {
      headline: useRef(),
      line: useRef(),
      copy: useRef(),
      img: useRef()
    };
  });
  useImperativeHandle(ref, () => ({
    cardRefArr
  }));
  return /* @__PURE__ */ React.createElement(Base, null, cards.map((card, index) => {
    return /* @__PURE__ */ React.createElement(Base, {
      key: index,
      as: "div",
      backgroundColor: index % 2 ? "white" : "#F2F2F2"
    }, /* @__PURE__ */ React.createElement(ContentGutter, null, /* @__PURE__ */ React.createElement(CardWrapper, {
      as: "div",
      display: "grid",
      gridTemplateColumns: ["auto", "1fr 1fr", "1fr 1fr"],
      gridTemplateRows: ["auto auto", "auto", "auto"],
      alignItems: "center"
    }, /* @__PURE__ */ React.createElement(Base, {
      as: "div",
      maxWidth: "450px",
      padding: "20px 0",
      gridRow: [2, 1, 1],
      gridColumn: index % 2 ? [1, 2, 2] : [1, 1, 1],
      gridColumnGap: "40px"
    }, /* @__PURE__ */ React.createElement(Text, {
      as: "h2",
      padding: "20px 0",
      textTransform: "uppercase",
      variant: ["medium-bold", "medium-bold", "large-bold"],
      ref: cardRefArr[index].headline,
      dangerouslySetInnerHTML: { __html: card.headline }
    }), /* @__PURE__ */ React.createElement(Base, {
      as: "div",
      backgroundColor: theme.colors.red,
      width: "30px",
      height: "6px",
      ref: cardRefArr[index].line
    }), /* @__PURE__ */ React.createElement(Text, {
      as: "p",
      padding: "20px 0",
      variant: ["x-small", "x-small", "small"],
      ref: cardRefArr[index].copy
    }, card.copy)), /* @__PURE__ */ React.createElement(Base, {
      as: "div",
      padding: "50px 0",
      gridRow: [1, 1, 1],
      gridColumn: index % 2 ? [1, 1, 1] : [1, 2, 2],
      ref: cardRefArr[index].img
    }, /* @__PURE__ */ React.createElement(StyledGatsbyImage, {
      alt: card.headline,
      image: card.image,
      width: "100%",
      height: "100%"
    })))));
  }));
});
export default CardGrid;
