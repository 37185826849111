var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useRef, useLayoutEffect } from "react";
import Page from "../components/Page";
import { Base } from "../styles";
import Hero from "../components/Hero";
import CalloutGrid from "../components/CalloutGrid";
import SeriesCTA from "../components/SeriesCTA";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Footer from "../components/Footer";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);
export default (props) => {
  const container = useRef(null);
  const heroRef = useRef(null);
  const calloutGridRef = useRef(null);
  const tl = useRef(null);
  const q = gsap.utils.selector(container);
  useLayoutEffect(() => {
    tl.current = gsap.timeline({});
    tl.current.from([
      heroRef.current.headlines[0].current,
      heroRef.current.headlines[1].current,
      heroRef.current.headlines[2].current
    ], {
      duration: 1,
      opacity: 0,
      stagger: 0.5,
      ease: "sine.out"
    });
    tl.current.from(heroRef.current.img.current, {
      duration: 2,
      scale: 1.5,
      opacity: 0,
      ease: "sine.out"
    }, "-=2");
    tl.current.from(heroRef.current.fullWidthTxt.current, {
      duration: 1,
      y: 100,
      opacity: 0,
      ease: "sine.out",
      scrollTrigger: {
        trigger: heroRef.current.fullWidthTxt.current,
        start: "bottom-=100 bottom",
        end: "+=100",
        scrub: 1
      }
    });
    calloutGridRef.current.cardRefArr.forEach((card, index) => {
      tl.current.from([card.headline.current, card.line.current, card.copy.current], {
        duration: 0.5,
        x: index % 2 === 0 ? -100 : 100,
        opacity: 0,
        stagger: 0.15,
        ease: "sine.out",
        scrollTrigger: {
          trigger: card.img.current,
          start: "top+=150 bottom",
          end: "+=300",
          scrub: 1
        }
      });
      tl.current.from(card.line.current, {
        duration: 0.8,
        width: 300,
        ease: "sine.out",
        scrollTrigger: {
          trigger: card.img.current,
          start: "top+=150 bottom",
          end: "+=300",
          scrub: 1
        }
      }, "-=0.3");
      tl.current.from(card.img.current, {
        duration: 0.8,
        ease: "sine.out",
        x: index % 2 === 0 ? 100 : -100,
        opacity: 0,
        scrollTrigger: {
          trigger: card.img.current,
          start: "top+=150 bottom",
          end: "+=300",
          scrub: 1
        }
      }, "-=0.3");
    });
  }, [container, heroRef, calloutGridRef]);
  const testingData = useStaticQuery(graphql`
    query {
      hero: file(
        relativePath: {
          eq: "testing/Hero_Kawasaki_2021_JH_5447_Dyno Screen Engine in Window_PSWork.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 1080
            aspectRatio: 1
            placeholder: BLURRED
            formats: [AUTO]
          )
        }
      }
      ctaimage: file(relativePath: {eq: "pre-footer.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            aspectRatio: 2
            placeholder: BLURRED
            formats: [AUTO]
          )
        }
      }

      cardGridImageOne: file(
        relativePath: {
          eq: "testing/cropped_Kawasaki_2021_JH_5530_Enviromental Chamber Exterior_PSWork_CROP_02.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 720
            aspectRatio: 1.5
            placeholder: BLURRED
            formats: [AUTO]
          )
        }
      }
      cardGridImageTwo: file(
        relativePath: {
          eq: "testing/cropped_Kawasaki_2021_JH_5414_Dyno Interior Setup_PSWork.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 720
            aspectRatio: 1.5
            placeholder: BLURRED
            formats: [AUTO]
          )
        }
      }
      cardGridImageThree: file(
        relativePath: {
          eq: "testing/Kawasaki_2021_JH_5443_Dyno Interior Vert_PSWork.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 720
            aspectRatio: 1.5
            placeholder: BLURRED
            formats: [AUTO]
          )
        }
      }
    }
  `);
  const heroImage = getImage(testingData.hero);
  const seriesCTABackgroundImage = getImage(testingData.ctaimage);
  const gridOne = getImage(testingData.cardGridImageOne);
  const gridTwo = getImage(testingData.cardGridImageTwo);
  const gridThree = getImage(testingData.cardGridImageThree);
  return /* @__PURE__ */ React.createElement(Page, __spreadProps(__spreadValues({}, props), {
    title: "Rigorous Testing | Kawasaki Testing",
    metaDescription: "With testing for extreme temperatures, rigorous wear and tear, and more, every single part of our engines is relentlessly scrutinized before it gets to you."
  }), /* @__PURE__ */ React.createElement(Base, {
    as: "div",
    display: "flex",
    flexDirection: "column",
    ref: container
  }, /* @__PURE__ */ React.createElement(Hero, {
    headline: ["Tested", "and", "proven."],
    copy: "We know that you\u2019re going to put your engine to the test every day, so we make sure it can stand up to anything before it leaves our factory. From extreme temperatures to rigorous wear and tear, every single part of our engines is relentlessly scrutinized before it gets to you.",
    image: heroImage,
    imageAlt: "Man inspecting Kawasaki product with magnifying tool.",
    ref: heroRef
  }), /* @__PURE__ */ React.createElement(CalloutGrid, {
    ref: calloutGridRef,
    cards: [
      {
        headline: "Environmental Chamber",
        copy: "We know that you work in every weather condition, so we make sure your engine does too. From extreme heat to sub freezing temperatures, we test our engines to make sure they will start fast and run smoothly so they never let you down.",
        image: gridOne
      },
      {
        headline: "Dynamometer",
        copy: "In order to have the confidence that every engine we produce can withstand whatever challenges it faces in the field, we put it through its paces with the dynamometer. Engines are repeatedly tested, disassembled, and inspected to ensure peak performance and durability. Kawasaki has developed test standards that are the toughest in the industry.",
        image: gridTwo
      },
      {
        headline: "Pressure Controlled Oxidizer",
        copy: "We don\u2019t just care about what goes into our engines, we also care what comes out of them. All of the dyno durability cells go through the PCO which traps and oxidizes the spent gasses, essentially cleaning and conditioning it which allows us to lower the amount of emissions into the atmosphere.",
        image: gridThree
      }
    ]
  }), /* @__PURE__ */ React.createElement(SeriesCTA, {
    headline: "Made to Work",
    copy: "Learn how each of our engines is designed and built to tackle the toughest jobs out there.",
    link: {
      url: "/engines/",
      text: "Explore Engines"
    },
    copySide: "left",
    backgroundImage: seriesCTABackgroundImage
  })), /* @__PURE__ */ React.createElement(Footer, null));
};
