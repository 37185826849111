import * as React from "react";
import { Base } from "../../../styles";
import Chevron from "./Chevron";
const ChevronBackground = ({
  children
}) => {
  return /* @__PURE__ */ React.createElement(Base, {
    as: "div",
    display: "grid",
    textAlign: "center",
    gridRow: "1",
    gridColumn: "1"
  }, /* @__PURE__ */ React.createElement(Base, {
    alignContent: "center",
    display: "grid",
    opacity: "0.25",
    gridArea: ["1/1"]
  }, /* @__PURE__ */ React.createElement(Chevron, null)), /* @__PURE__ */ React.createElement(Base, {
    as: "div",
    gridArea: ["1/1"],
    position: "relative",
    display: "grid",
    placeItems: "center"
  }, children));
};
export default ChevronBackground;
