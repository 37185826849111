import React, { forwardRef, Fragment, useImperativeHandle, useRef } from "react";
import { Base, Text, StyledGatsbyImage } from "../../styles";
import theme from "../../styles/theme";
import ChevronBackground from "./ChevronBackground";
const Hero = forwardRef(({ headline, copy, image, imageAlt }, ref) => {
  const txt = useRef();
  const img = useRef();
  const fullWidthTxt = useRef();
  let headlines = [];
  useImperativeHandle(ref, () => ({
    txt,
    img,
    fullWidthTxt,
    headlines
  }));
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Base, {
    as: "section",
    color: theme.colors.white,
    bg: theme.colors.red,
    display: "grid",
    gridTemplateColumns: ["auto", "1fr 1fr", "1fr 1fr"],
    gridTemplateRows: ["auto, auto", "auto", "auto"]
  }, /* @__PURE__ */ React.createElement(ChevronBackground, null, /* @__PURE__ */ React.createElement(Text, {
    as: "h1",
    variant: ["x-large", "x-large", "x-large", "xx-large"],
    textTransform: "uppercase",
    padding: "10px",
    ref: txt
  }, headline.map((line, key) => {
    headlines[key] = useRef();
    return /* @__PURE__ */ React.createElement(Fragment, {
      key
    }, /* @__PURE__ */ React.createElement("span", {
      className: "line",
      ref: headlines[key]
    }, line), /* @__PURE__ */ React.createElement("br", null));
  }))), /* @__PURE__ */ React.createElement(Base, {
    gridRow: ["2", "1", "1"],
    gridColumn: ["1", "2", "2"],
    overflow: "hidden"
  }, /* @__PURE__ */ React.createElement("div", {
    ref: img
  }, /* @__PURE__ */ React.createElement(StyledGatsbyImage, {
    width: "100%",
    height: "100%",
    image,
    alt: imageAlt
  })))), /* @__PURE__ */ React.createElement(Base, {
    as: "section",
    color: theme.colors.black,
    bg: theme.colors.white
  }, /* @__PURE__ */ React.createElement(Text, {
    as: "p",
    variant: ["small", "medium"],
    textAlign: "center",
    padding: ["50px", "100px"],
    maxWidth: "990px",
    margin: "0 auto",
    ref: fullWidthTxt
  }, copy)));
});
export default Hero;
